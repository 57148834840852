<template>
  <div class="wrap">
    <s-header :name="'订单确认'"></s-header>
    <div class="addressWrap" v-if="details.hasOwnProperty('address')" @click="gotoAddress">
      <div>
        <p>
          <span>{{ addressInfo.realname }}</span>
          <span>{{ addressInfo.mobile }}</span>
        </p>
        <p style="margin-top: 10px;color: #999">{{ addressInfo.address_name }} {{
            addressInfo.address_details
          }}</p>
      </div>
      <span>&gt;</span>
    </div>
    <van-cell v-else title="新增收货地址" is-link to="/good/site"/>
    <div v-for="(item,index) in details.products" :key="index" style="padding-bottom: 50px">
      <div v-for="(itemA,index) in item.products" :key="index">
        <div class="goods">
          <p class="title">
            <span style="font-size: 16px;color: #333">{{ itemA.product_name }}</span>
            <span style="font-size: 16px;color: red">{{ itemA.price }}</span>
          </p>
          <div class="flex">
            <img style="width: 80px" :src="itemA.product_picture"/>
            <span>共{{ itemA.num }}件</span>
          </div>
        </div>
      </div>
      <div class="goodsTip">
        <span style="margin-right: 8px">备注:</span>
        <input v-model="desArr" placeholder="建议留言前先跟商家沟通"/>
      </div>
      <div class="goods">
        <p class="title" v-if="item.coupons.length>0" @click="showCouponBox(item)">
          <span style="font-size: 16px;color: #333">优惠卷</span>
          <span style="font-size: 16px;color: red"> {{
              couponItem.length > 0 && couponItem[index].title ? couponItem[index].title : '选择优惠券'
            }}</span>
        </p>
        <p class="title">
          <span style="font-size: 16px;color: #333">商品金额</span>
          <span style="color: #333;font-weight: bold">¥<span style="font-size: 16px">{{
              amountGoods
            }}</span></span>
        </p>
        <p class="flex">
          <span style="font-size: 16px;color: #333">配送费</span>
          <span style="color: #333;font-weight: bold">¥<span
              style="font-size: 16px">{{ details.preview.shipping_money }}</span></span>
        </p>
        <p class="flex">
          <span style="font-size: 16px;color: #333">优惠金额</span>
          <span style="color: #333;font-weight: bold">-¥<span
              style="font-size: 16px">{{ discountAmount }}</span></span>
        </p>
        <p class="flex">
          <span style="font-size: 16px;color: #333">赠送积分</span>
          <span style="color: #333;font-weight: bold">¥<span
              style="font-size: 16px">{{ item.give_point }}</span></span>
        </p>
      </div>
    </div>
    <div class="pay">
      <van-submit-bar :price="total*100" button-text="去支付" @submit="onSubmit"/>
    </div>

    <van-action-sheet v-model:show="coupon" style="background: #f3f3f3">
      <div class="coupon-item" v-for="(item, index) in couponData.coupons" :key="index"
           style="margin-bottom: 10px" @click="claimCoupons(item,couponData)">
        <div class="con">
          <div class="left">
            <span class="title">{{ item.title }}</span>
            <span class="time">有效期{{ item.start_time }}至{{ item.end_time }}</span>
          </div>
          <div class="right">
            <span class="price" v-if="item.money">{{ item.money }}</span>
            <span class="price-discount" v-else>{{ `${item.discount}折` }}</span>
            <span>满{{ item.at_least }}可用</span>
          </div>
          <div class="circle l"></div>
          <div class="circle r"></div>
        </div>
        <div class="tips">
          <span v-show="item.couponType.max_fetch">
            {{ item.couponType.range_type === 0 ? '部分产品使用' : '全场产品使用' }} 领取上限{{ item.couponType.max_fetch }}
          </span>
          <span>
            {{ item.couponType.term_of_validity_type === 0 ? '固定时间' : '领取之日起' }}生效
          </span>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import {getLocal, deleteLocal} from '@/common/js/utils'
import sHeader from '@/components/SimpleHeader'
import {onMounted, reactive, toRefs, ref, computed} from "vue";
import {useRouter} from "vue-router";
import {orderPreview} from "../../../service/product";
import {createOrderPay} from "../../../service/order";
import {showFailToast} from "vant";

export default {
  components: {
    sHeader
  },
  name: "order",
  setup() {
    const router = useRouter()
    const desArr = ref('')
    const coupon = ref(false);
    const state = reactive({
      // 商品详情
      details: {},
      // 地址详情
      addressInfo: {},
      // 选择数量
      value: 1,
      couponData: [],
      couponItem: [],
      couponsNumber: 0
    })
    onMounted(async () => {
      const val = getLocal('addressVal') && JSON.parse(getLocal('addressVal'));
      let details = JSON.parse(router.currentRoute.value.query.data)
      const {data} = await orderPreview({
        type: details.type,
        data: JSON.stringify(details.data)
      })
      state.details = data
      state.addressInfo = data.address
      if (val) {
        state.addressInfo = {
          realname: val.name,
          mobile: val.tel,
          address_name: val.address,
          id: val.id
        }
      }
      state.details.products.forEach(item => {
        if (state.details.coupons.length > 0) {
          for (let ii in state.details.coupons) {
            let zongCoupons = Object.assign(
                {},
                state.details.coupons[ii]
            )
            let couslength = false
            for (let cous in state.details.products[item].coupons) {
              if (
                  state.detailsl.products[item].coupons[cous].id == zongCoupons.id
              ) {
                couslength = true
              }
            }
            if (!couslength) {
              state.details.products[item].coupons.push(zongCoupons)
            }
            state.details.products[item].coupon_id = ''
            zongCoupons = {}
          }
        }
      })

    })

    const total = computed(() => {
      if (state.details.preview) {
        const realAmount =
            amountGoods.value -
            discountAmount.value +
            parseFloat(state.details.preview.shipping_money)

        var realCont = (
            Math.floor(realAmount * 100) / 100 || 0
        ).toFixed(2)
        console.log(realCont)
        return realCont <= 0 ? 0.01 : realCont
      } else {
        return ''
      }
    })
    const amountGoods = computed(() => {
      let amount = 0
      state.details.products &&
      state.details.products.forEach(item => {
        item.products.forEach(cItem => {
          amount += parseInt(cItem.num, 10) * parseFloat(cItem.price)
        })
      })
      return Math.floor(amount * 100) / 100
    })
    const discountAmount = computed(() => {
      return discountAmountFun()
    })

    function discountAmountFun() {
      state.couponsNumber = 0
      for (var i in state.couponItem) {
        const discountMoney = Math.floor(((100 - state.couponItem[i].discount) / 100) * amountGoods.value * 100) / 100
        state.couponsNumber +=
            parseInt(state.couponItem[i].type, 10) === 2
                ? discountMoney
                : state.couponItem[i].money || 0
      }
      return state.couponsNumber
    }

    // 领取优惠券
    async function showCouponBox(item) {
      coupon.value = true
      state.couponData = item
    }

    // 领取优惠券
    async function claimCoupons(item, couponsList) {
      for (let i in state.couponItem) {
        if (state.couponItem[i].id == item.id) {
          showFailToast(`已使用${state.couponItem[i].title}`)
          return
        }
      }
      if (amountGoods.value < item.at_least) {
        showFailToast('不满足优惠券使用条件~')
        return
      }
      if (state.couponItem.length < state.details.products.length) {
        if (state.couponItem.length > 0) {
          for (let i in state.couponItem) {
            if (state.couponItem[i].merchant_id === item.merchant_id) {
              state.couponItem[i] = item
            } else {
              state.couponItem.push(item)
            }
          }
        } else {
          state.couponItem.push(item)
        }
      } else {
        state.couponsNumber = 0
        state.couponItem[0] = item
      }

      let index = state.details.products.indexOf(couponsList)
      state.details.products[index].coupon_id = item.id
      coupon.value = false
    }

    function gotoAddress() {
      router.push('/good/site')
    }

    async function onSubmit() {
      if(JSON.stringify(state.addressInfo) === '{}') {
        showFailToast('请先选择收货地址!')
      } else {
        let coupon_id
        if (state.couponItem.length > 0) {
          coupon_id = ''
          state.couponItem.map(item => {
            coupon_id += item.id
          })
        }
        const {data} = await createOrderPay({
          address_id: state.addressInfo.id,
          company: [],
          data: JSON.stringify(JSON.parse(router.currentRoute.value.query.data).data),
          desc: [desArr.value],
          feeids: [],
          invo: [],
          pickup: [],
          shipping_type: [],
          coupon_id: coupon_id,
          type: JSON.parse(router.currentRoute.value.query.data).type
        })
        deleteLocal('addressVal')
        router.push({path: '/good/pay', query: {ids: data.ids, coupon_id: data.coupon_id}})
      }
    }


    return {
      gotoAddress,
      onSubmit,
      desArr,
      coupon,
      claimCoupons,
      showCouponBox,
      amountGoods,
      discountAmount,
      total,
      discountAmountFun,
      ...toRefs(state)
    }
  }
}
</script>

<style scoped lang="less">
/* 优惠券列表 */
.coupon-item {
  display: flex;
  flex-direction: column;
  margin: 10px 14px;
  padding: 10px;

  .con {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 10px;
    background: #fff;

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: 0;
      border-bottom: 1px dashed #f3f3f3;
      transform: scaleY(50%);
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    overflow: hidden;
    height: 100px;
  }

  .title {
    font-size: 16px;
    color: #303133;
    margin-bottom: 10px;
  }

  .time {
    font-size: 16px;
    color: #909399;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #606266;
    height: 100px;
  }

  .price {
    font-size: 16px;
    color: #fa436a;

    &:before {
      content: '￥';
      font-size: 16px;
    }
  }

  .price-discount {
    font-size: 16px;
    color: #fa436a;
  }

  .tips {
    font-size: 16px;
    color: #909399;
    line-height: 30px;
    padding-left: 30px;
  }

  .circle {
    position: absolute;
    left: -6px;
    bottom: -10px;
    z-index: 10;
    width: 20px;
    height: 20px;
    background: #f3f3f3;
    border-radius: 100px;

    &.r {
      left: auto;
      right: -6px;
    }
  }

}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap {
  background: #eee;

  .addressWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    padding: 20px;
    background: #fff;
    font-size: 16px;
    border-radius: 8px;

    p {
      margin: 0;
    }
  }
}

::v-deep .van-cell {
  width: auto;
  margin: 10px 10px 0 10px;
  background: #fff;
  border-radius: 12px;
  font-size: 20px;
  color: #333;
  font-weight: bold;
  padding: 20px 10px;
}

.goods {
  margin: 20px 10px;
  background: #fff;
  padding: 20px;
  border-radius: 12px;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.goodsTip {
  margin: 20px 10px;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  font-size: 16px;
  color: #999;
}

.pay {
  position: fixed;
  bottom: 0;
  border-top: 1px solid #ccc;

  ::v-deep .van-submit-bar__bar {
    justify-content: space-between;
  }

  ::v-deep .van-submit-bar__text {
    flex: none;
  }
}
</style>
